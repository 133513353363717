export const PM_CREDIT_CARD_DOMESTIC = "creditcard-domestic";
export const PM_CREDIT_CARD_INTERNATIONAL = "creditcard-international";
export const PM_DIRECT_DEBIT = "directdebit";
export const PM_ALI_PAY = "alipay";
export const PM_WE_CHAT_PAY = "wechatpay";
export const PM_UNION_PAY = "unionpay";
export const ACQUIRING = "ACQUIRING";
export const SECURE_TOKEN = "SECURE_TOKEN";
export const ALPHA_SUCCESS_CODE = "1000";
export const HTTP_STATUS_OK = "200";
export const LINK_ACCESS_TOKEN = "LINK_ACCESS_TOKEN";
export const HPF_INIT_TOKEN = "ALPHA_TOKEN";
export const CREDITCARD = "creditcard";
export const DOMESTIC = "domestic";
export const INTERNATIONAL = "international";
export const PAYMENT_METHODS = [{
    "payment_method": CREDITCARD
}];

export const PM_STATIC_DATA = {
    [PM_CREDIT_CARD_DOMESTIC]: {
        title: "Australian Credit Card",
        logo: 'assets/payment-methods/creditcard.png',
        frameStyleClass: 'pm-credit-card',
        disabled: false

    },
    [PM_CREDIT_CARD_INTERNATIONAL]: {
        title: "International Credit Card",
        logo: 'assets/payment-methods/creditcard.png',
        frameStyleClass: 'pm-credit-card',
        disabled: false
    },
    [PM_DIRECT_DEBIT]: {
        title: "Direct Debit",
        logo: 'assets/payment-methods/directdebit.png',
        frameStyleClass: 'pm-direct-debit',
        disabled: false
    },
    [PM_ALI_PAY]: {
        title: "Ali Pay",
        logo: 'assets/payment-methods/alipay.png',
        frameStyleClass: 'pm-ali-pay',
        disabled: true
    },
    [PM_WE_CHAT_PAY]: {
        title: "WeChat Pay",
        logo: 'assets/payment-methods/wechatpay.png',
        frameStyleClass: 'pm-we-chat-pay',
        disabled: true
    },
    [PM_UNION_PAY]: {
        title: "Union Pay",
        logo: 'assets/payment-methods/unionpay.png',
        frameStyleClass: 'pm-union-pay',
        disabled: true
    },
    [CREDITCARD]: {
        title: "Credit Card",
        logo: 'assets/payment-methods/creditcard.png',
        frameStyleClass: 'pm-credit-card',
        disabled: false

    }
}
export const PAYMENT_REQUEST_SUCCESS = "initPaymentSuccess";
export const PAYMENT_REQUEST_FAILED = "initPaymentFailed";
export const NONCE_REQUEST_FAILED = "nonceGenerationFailed";
export const HPF_INIT_TOKEN_REQUEST_SUCCESS = "initTokenAcquired";
export const HPF_INIT_TOKEN_REQUEST_FAILED = "initTokenFailed";