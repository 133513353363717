import React, {useState} from "react";
import { isMobile } from "../utils/CommonUtils";
import PaymentForm from "./PaymentForm";
import PaymentInvoice from "./PaymentInvoice";
import PaymentMethodList from "./PaymentMethodList";
import HPFForm from "./HPFForm";
import {ACQUIRING, CREDITCARD} from "../utils/constants";
import {fetchIframeUrl} from "../utils/RequestHandlers";

function PageContent(props) {
  const {
    currentView,
    paymentMethods,
    isLoading,
    paymentMethod,
    paymentTransactionId,
    paymentFrameUrl,
    paymentQRCode,
    paymentWXCodeURL,
    paymentInfo,
    onPaymentMethodSelect,
    onProceed,
    onProcessedFeeCalculated,
    processingFee,
    onGetProcessedFee,
    onCardNumberReceivedCall,
    getCardNumberCall
  } = props;

  const [iframeUrl, setIframeUrl] = useState(null);

  if (isLoading) {
    return <></>;
  }

  let showFees = paymentInfo.merchant.page_configuration.show_fees;

  function processView() {
    return <div className="payment-invoice-wrapper">
      <PaymentInvoice
          paymentInfo={paymentInfo}
          paymentMethod={paymentMethod}
          processingFee={processingFee}
          processedFeeFetch={onGetProcessedFee.bind(this)}
          getCardNumberCallCall={getCardNumberCall.bind(this)}
      />

      <button
          className="btn-primary"
          disabled={!paymentMethod}
          onClick={onProceed.bind(this)}
      >
        Proceed
      </button>

      <div className="warning-message">
        * Please select a payment method to proceed
      </div>
    </div>;
  }

  function processCreditCardView() {
    return <div className="payment-invoice-wrapper">
      <PaymentInvoice
          paymentInfo={paymentInfo}
          paymentMethod={paymentMethod}
          processingFee={processingFee}
          processedFeeFetch={onGetProcessedFee.bind(this)}
          getCardNumberCallCall={getCardNumberCall.bind(this)}
      />

      {paymentInfo.account_type === ACQUIRING &&
          <HPFForm
              paymentInfo={paymentInfo}
              paymentLinkToken={paymentInfo.access_token}
              merchantId={paymentInfo.merchant.merchant_id}
              type={paymentMethod.payment_method.split("-")[1]}
              onCalculated={onProcessedFeeCalculated.bind(this)}
              onCardNumberReceivedCallCall={onCardNumberReceivedCall.bind(this)}
              method={paymentMethod.payment_method}
          />
      }

      {paymentInfo.account_type !== ACQUIRING &&
          iframeUrl && <iframe src={iframeUrl} title={"PaymentIframe"}
                  frameBorder="0" border="0"
                  width="100% " height="360px"></iframe>
      }

    </div>;
  }

  async function onPaymentMethodSelectHandler(selectedPaymentMethod) {
    onPaymentMethodSelect(selectedPaymentMethod);
    if (paymentInfo.account_type !== ACQUIRING) {
      const iframeUrl = await fetchIframeUrl(selectedPaymentMethod, paymentInfo.access_token);
      setIframeUrl(iframeUrl);
    }
  }

  switch (currentView) {
    case "payment-method-selection":
      return (
        <div className="payment-method-selection">
          <div className="payment-method-list-wrapper">
            <PaymentMethodList
                paymentInfo={paymentInfo}
                showFees={showFees}
              paymentMethods={paymentMethods}
              paymentMethod={paymentMethod}
              onSelect={onPaymentMethodSelectHandler}
            />
          </div>

          {!isMobile() && <div className="flex-spacer"></div>}

          {!isMobile() && !paymentMethod && processView.call(this)}

          {!isMobile() && paymentMethod
              && (paymentMethod.payment_method !== "creditcard-international"
                  && paymentMethod.payment_method !== "creditcard-domestic" && paymentMethod.payment_method !== CREDITCARD)
              && processView.call(this)
          }

          {!isMobile() && paymentMethod
              && (paymentMethod.payment_method === "creditcard-international"
                  || paymentMethod.payment_method === "creditcard-domestic" || paymentMethod.payment_method === CREDITCARD)
              && processCreditCardView.call(this)}

        </div>
      );
    case "payment-form":
      return (
        <PaymentForm
            paymentInfo={paymentInfo}
            paymentMethod={paymentMethod}
            paymentTransactionId={paymentTransactionId}
            paymentFrameUrl={paymentFrameUrl}
            paymentQRCode={paymentQRCode}
            paymentWXCodeURL={paymentWXCodeURL}
            onCalculatedProp={onProcessedFeeCalculated.bind(this)}
            onCardNumberReceivedC={onCardNumberReceivedCall.bind(this)}
            iframeUrl={iframeUrl}
        />
      );
    default:
      return <div></div>;
  }
}

export default PageContent;
