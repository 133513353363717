import React, { useState } from "react";
import { handleDirectDebitRequest } from "../utils/RequestHandlers";
import FormField from "./FormField";
import LoadingSpinner from "./LoadingSpinner";

function DirectDebitForm(props) {
  const { paymentInfo } = props;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    account_name: null,
    account_bsb: null,
    account_no: null,
    customer_consent: null,
  });
  const [requestStatus, setRequestStatus] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const validators = {
    account_name: (val) => {
      return val && val.length > 3 && val.length <= 32;
    },
    account_bsb: (val) => {
      return val && val.length > 4 && val.length <= 6 && /^\d+$/.test(val);
    },
    account_no: (val) => {
      return val && val.length > 6 && val.length <= 10 && /^\d+$/.test(val);
    },
  };

  const openTerms = (e) => {
    e.preventDefault();
    setShowTerms(true);
  };

  const closeTerms = (e) => {
    e.preventDefault();
    setShowTerms(false);
  };

  const submit = (e) => {
    e.preventDefault();

    let formObj = {
      payload: {
        request_type: "payment",
        payment_method: "directdebit",
      },
      errors: {},
    };
    let hasErrors = false;

    ["account_name", "account_bsb", "account_no"].forEach((key) => {
      if (e && e.target && e.target[key]) {
        const val = e.target[key].value.trim();
        if (validators[key](val)) {
          formObj.payload[key] = val;
        } else {
          formObj.errors[key] = true;
          hasErrors = true;
        }
      }
    });

    formObj.payload.customer_consent = !!e.target.customer_consent.checked;
    formObj.errors.customer_consent = !e.target.customer_consent.checked;
    hasErrors = !e.target.customer_consent.checked;

    setErrors(formObj.errors);
    if (hasErrors) {
      return;
    }

    setLoading(true);
    handleDirectDebitRequest(formObj.payload, paymentInfo.access_token)
      .then((resp) => {
        setRequestStatus("success");
      })
      .catch((err) => {
        setRequestStatus("failed");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="pm-direct-debit">
      {loading && <LoadingSpinner />}

      {showTerms && <DirectDebitTerms onClose={closeTerms} />}

      {requestStatus === "failed" && (
        <div className="status-alert status-error">
          Unable to submit direct debit request.
        </div>
      )}

      {requestStatus === "success" && (
        <div className="status-alert status-success">
          Direct debit request successfully submitted. Please allow 2 business
          days for funds to be debited.
        </div>
      )}

      <form className="direct-debit-form" onSubmit={submit}>
        <FormField
          id="account_name"
          name="account_name"
          type="text"
          label="Account Name"
          errorMessage={
            errors.account_name ? "A valid account name required." : null
          }
          isRequired={true}
          maxLength={32}
          ariaDesc="account-name-message"
        />

        <FormField
          className="form-group--small"
          id="account_bsb"
          name="account_bsb"
          type="text"
          label="BSB"
          errorMessage={errors.account_bsb ? "A valid BSB required." : null}
          isRequired={true}
          isNumber={true}
          maxLength={6}
          ariaDesc="account-bsb-message"
        />

        <FormField
          className="form-group--medium"
          id="account_no"
          name="account_no"
          type="text"
          label="Account Number"
          errorMessage={
            errors.account_no ? "A valid account number required." : null
          }
          isRequired={true}
          isNumber={true}
          maxLength={10}
          ariaDesc="account-no-message"
        />

        <div className="form-group m-top-3">
          <div className="checkbox">
            <input
              type="checkbox"
              className="form-check-input"
              id="customer_consent"
              name="customer_consent"
            />
            <span className="check-icon" />
            <span className="space" />
            <label
              htmlFor="customer_consent"
              className={`${errors.customer_consent ? "error" : ""}`}
            >
              I have read and understood the{" "}
              <span className="link" onClick={openTerms}>
                Terms and Conditions
              </span>{" "}
              and authorise this Direct Debit request.
            </label>
          </div>
        </div>

        <div id="direct-debit-message" className="error-message"></div>

        <div className="m-top-2">
          <button type="submit" className="btn-primary" disabled={loading}>
            Submit Request
          </button>
        </div>
      </form>
    </div>
  );
}

function DirectDebitTerms(props) {
  const { onClose } = props;
  return (
    <div className="modal-wrapper direct-debit-terms-modal">
      <div className="modal-content">
        <div className="modal-header">Direct Debit - Terms and Conditions</div>

        <div className="modal-body">
          <p>
            You request and authorise Novatti Acquiring Holdings Pty Ltd on
            behalf of the recon Australia Pty Ltd or its affiliates with whom
            you have a direct debit agreement, to arrange through its own
            financial institution, to debit from your nominated account an
            amount as requested.
          </p>

          <p>
            This debit or charge will be made through the Bulk Electronic
            Clearing System (BECS) from your account held at the financial
            institution you have nominated below and will be subject to the
            terms and conditions of the Direct Debit Request Service Agreement.
          </p>

          <p>
            Novatti Acquiring Holdings Pty Ltd does not accept any liability for
            the provision, merchantable quality or fitness for purpose of the
            underlying goods or services provided to the User by the recon
            Australia Pty Ltd or its affiliates and therefore the User holds
            Novatti Acquiring Holdings Pty Ltd harmless for any claim that may
            arise from the non-provision of services by the recon Australia Pty
            Ltd or its affiliates or any other claim that may be made against
            the Payment Initiator and/or merchant under Consumer Law.
          </p>
        </div>

        <div className="modal-footer">
          <button className="btn btn-default" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default DirectDebitForm;
