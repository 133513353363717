import React from "react";

function ErrorPage(props) {
  const refresh = () => {
    window.location.reload();
  };

  return (
    <div className="error-page-wrapper">
      <div className="error-page">
        <img src="assets/client/logo-dark.png" alt="Novatti" width="100" />

        <div className="title">Oops...</div>

        <div className="message">
          Something went wrong. We couldn't load the payment page. Please try
          again.
        </div>

        <button className="action btn-primary-border" onClick={refresh}>
          Reload
        </button>
      </div>

      <div className="page-footer">
        <img src="assets/novatti_logo.svg" alt="Novatti" width="100" />
        <div>Novatti Pty Ltd © 2022</div>
      </div>
    </div>
  );
}

export default ErrorPage;
