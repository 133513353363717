import React from "react";
import PaymentMethod from "./PaymentMethod";
import {ACQUIRING, CREDITCARD} from "../utils/constants";

function PaymentMethodList(props, state) {
    const {paymentMethod} = props;

    return (
        <div className="payment-method-list">
            <div className="label">Select payment method</div>
            <div className="payment-method-list-wrapper">
                {props.paymentMethods.map((method) => (
                    <PaymentMethod
                        showFees={props.showFees}
                        key={method.payment_method}
                        id={method.payment_method}
                        selectedPaymentMethod={paymentMethod}
                        disabled={getDisableFlag(props.paymentInfo, method.payment_method)}
                        onClick={() => props.onSelect(method)}
                    />
                ))}
            </div>
        </div>
    );
}

function getDisableFlag(paymentInfo, paymentMethod) {
    if (paymentInfo.account_type === ACQUIRING && paymentMethod === CREDITCARD) {
        return false;
    } else {
        let methods = paymentInfo?.merchant?.payment_methods;
        if (!methods) {
            //if any payment methods are not defined in response enable all
            return false;
        }

        let enabled = methods?.find(x => paymentMethod === x.payment_method_code)?.enabled;
        if (!enabled) {
            return true;
        }
        return !enabled;
    }
}

export default PaymentMethodList;
