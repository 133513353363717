/* eslint-disable no-undef */
import "bootstrap/dist/css/bootstrap.css";
import React, {useEffect, useState} from "react";
import {fetchHPFInitToken, handleCreditPaymentRequest, surcharge,} from "../utils/RequestHandlers";
import LoadingSpinner from "./LoadingSpinner";
import Card from "react-bootstrap/Card";
import {
  ALPHA_SUCCESS_CODE, HPF_INIT_TOKEN,
  HPF_INIT_TOKEN_REQUEST_FAILED,
  HPF_INIT_TOKEN_REQUEST_SUCCESS, HTTP_STATUS_OK,
  NONCE_REQUEST_FAILED,
  PAYMENT_REQUEST_FAILED,
  PAYMENT_REQUEST_SUCCESS,
  SECURE_TOKEN
} from "../utils/constants";

const HPFForm = ({paymentLinkToken, merchantId, type, paymentInfo, onCalculated, onCardNumberReceivedCallCall, method}) => {
  const [loading, setLoading] = useState(true);
  const [paymentRequestStatus, setPaymentRequestStatus] = useState(null);
  const [initTokenRequestStatus, setInitTokenRequestStatus] = useState(null);
  const [hpfInitToken, setHpfInitToken] = useState(null);
  const [alphaMid, setAlphaMid] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [initTokenFetched, setInitTokenFetched] = useState(false)
  const [processingFeeCalculated, setProcessingFeeCalculated] = useState(false);
  const [surchargeSuccess, setSurchargeSuccess] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [surchargeAmount, setSurchargeAmount] = useState(null);
  const [issuerCountryCode, setIssuerCountryCode] = useState(null);

  const prepareCustomerName = (firstName, lastName) => {
    let finalString;
    if (!firstName) {
      finalString = lastName;
    } else if (!lastName) {
      finalString = firstName;
    } else if (!firstName && !lastName) {
      finalString = "";
    } else {
      finalString = firstName + " " + lastName
    }
    return finalString;
  }

  const prepareMaskedCardNumber = (bin, last4) => {
    return bin + "**************" + last4;
  }

  const processSurcharge = async (response) => {
    let {ResponseCode, Token} = response;
    if (ResponseCode === ALPHA_SUCCESS_CODE) {
      sessionStorage.setItem(SECURE_TOKEN, Token);
      try {
        const surchargeResponse = await surcharge(
            Token,
            paymentLinkToken,
            paymentInfo.customer.id,
            alphaMid,
            String(parseFloat(paymentInfo.balance ? paymentInfo.balance : 0.00))
        );
        if (surchargeResponse.alpha_response.response_code !== ALPHA_SUCCESS_CODE) {
          setSurchargeSuccess(false)
        } else {
          onCalculated(surchargeResponse.alpha_response.surcharge_amount);
          setSurchargeAmount(surchargeResponse.alpha_response.surcharge_amount);
          setIssuerCountryCode(surchargeResponse.alpha_response.issuer_country_code);
          onCardNumberReceivedCallCall(
              prepareMaskedCardNumber(
                  surchargeResponse.alpha_response.bin,
                  surchargeResponse.alpha_response.last4
              )
          )
          setProcessingFeeCalculated(true);
          setSurchargeSuccess(true);
          setPaymentProcessing(false);
          setSubmitButtonEnabled(true);
        }
      } catch (error) {
        setSurchargeSuccess(false)
      }
    } else {
      setPaymentRequestStatus(null);
    }
  }

  const performPayment = async () => {
    try {
      setLoading(true);
      const creditCardPaymentResponse = await handleCreditPaymentRequest({
        secureToken: sessionStorage.getItem(SECURE_TOKEN),
        bearerToken: hpfInitToken,
        paymentLinkToken,
        type,
        amount: paymentInfo.balance ? paymentInfo.balance: 0.00,
        userId: paymentInfo.reference_id,
        residentId: paymentInfo.reference_id,
        customerName: prepareCustomerName(
            paymentInfo.customer.first_name,
            paymentInfo.customer.last_name
        ),
        surchargeAmount: surchargeAmount,
        method: method,
        issuerCountryCode: issuerCountryCode
      });
      if (creditCardPaymentResponse.status === HTTP_STATUS_OK) {
        setSubmitButtonEnabled(false);
        setPaymentRequestStatus(PAYMENT_REQUEST_SUCCESS);
      } else {
        setProcessingFeeCalculated(false);
        setSubmitButtonEnabled(true);
        setPaymentRequestStatus(PAYMENT_REQUEST_FAILED);
      }
    } catch (error) {
      setProcessingFeeCalculated(false);
      setPaymentRequestStatus(PAYMENT_REQUEST_FAILED);
    }
    setLoading(false);
    setPaymentProcessing(false);
  };

  const performSurcharge = async () => {
    HPF.generateToken(processSurcharge, "txn" + Date.now());
  }

  const handleSurcharge = () => {
    try {
      performSurcharge().then((x) => {
        setPaymentProcessing(true);
      });
    } catch (error) {
      setPaymentRequestStatus(NONCE_REQUEST_FAILED);
      setPaymentProcessing(false);
    }
  };

  const isFormContentVisible = () =>
      !loading &&
      initTokenRequestStatus === HPF_INIT_TOKEN_REQUEST_SUCCESS &&
      !paymentRequestStatus && !surchargeSuccess;

  const initHpfElements = async () => {
    try {
      const {hpfInitToken, alphaMid} = await fetchHPFInitToken(
          merchantId,
          paymentLinkToken
      );
      sessionStorage.setItem(HPF_INIT_TOKEN, hpfInitToken);
      hpfOptions = {
        merchantID: alphaMid,
        token: hpfInitToken,
        cardNumber: {
          placeholder: 'Card Number'
        }
      };
      HPF.init(hpfOptions);
      setAlphaMid(alphaMid);
      setHpfInitToken(hpfInitToken);
      setInitTokenRequestStatus(HPF_INIT_TOKEN_REQUEST_SUCCESS);
    } catch (error) {
      setInitTokenRequestStatus(HPF_INIT_TOKEN_REQUEST_FAILED);
    }
    setLoading(false);
  };

  const retry = async () => {
    setLoading(true);
    setPaymentRequestStatus(null);
    setSurchargeSuccess(false);
    setProcessingFeeCalculated(false);
    onCalculated(null);
    onCardNumberReceivedCallCall(null);
    HPF.destroy();
    initHpfElements().then((x) => console.log("HPF initialized."));
  };

  useEffect(() => {
    setLoading(true);
    setPaymentRequestStatus(null);
    setInitTokenRequestStatus(null);
    setHpfInitToken(null);
    setPaymentProcessing(false);
    setInitTokenFetched(false);
    initHpfElements().then((x) => console.log("HPF initialized"));
    return () => {
      HPF.destroy();
    };
  }, [merchantId, paymentLinkToken, type]);

  //To do
  //move inline styles in to css classes
  //replace div tags with relevant react-bootstrap component tags
  return (
      <div>
        {loading && <LoadingSpinner/>}
        <div>
          {paymentRequestStatus === NONCE_REQUEST_FAILED && (
              <div className="status-alert status-error">
                Unable to submit credit card payment.
              </div>
          )}
          {isFormContentVisible() && (
              <div className="row" id="paymentForm">
                <div className="col-md-12 mx-auto">
                  <div className="card bg-white shadow-sm">
                    <Card.Body>
                      <div
                          id="credit-card"
                          className="tab-pane fade show active pt-3"
                      >
                        <div className="form-group">
                          <label htmlFor="username">
                            <h6>CardHolder</h6>
                          </label>
                          <div
                              id="cardHolder"
                              className="form-control"
                              data-hpf="cardHolder"
                              style={{height: "2.5rem"}}
                          ></div>
                          <span
                              id="cardHolderError"
                              className="error-text hpf-form-error"
                          ></span>
                        </div>
                        <div className="form-group">
                          <label
                              htmlFor="cardNumber"
                              style={{marginTop: "1.3rem"}}
                          >
                            <h6>Card Number</h6>
                          </label>
                          <div
                              id="cardNumber"
                              className="form-control"
                              data-hpf="cardNumber"
                              style={{height: "2.5rem"}}
                          ></div>
                          <span
                              id="cardNumberError"
                              className="error-text hpf-form-error"
                          ></span>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group mb-4">
                              <label style={{marginTop: "1.3rem"}}>
                            <span className="hidden-xs">
                              <h6>Expiry Date</h6>
                            </span>
                              </label>
                              <div className="form-group">
                                <div
                                    id="cardExpiry"
                                    className="form-control"
                                    data-hpf="cardExpiry"
                                    style={{height: "2.5rem"}}
                                ></div>
                                <span
                                    id="cardExpiryError"
                                    className="error-text hpf-form-error"
                                ></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group mb-4">
                              <label
                                  style={{marginTop: "1.3rem"}}
                                  data-toggle="tooltip"
                                  title="Three digit CV code on the back of your card"
                              >
                                <h6>
                                  CVV
                                  <i className="fa fa-question-circle d-inline"></i>
                                </h6>
                              </label>
                              <div
                                  id="cvv"
                                  className="form-control"
                                  data-hpf="cvv"
                                  style={{height: "2.5rem"}}
                              ></div>
                              <span
                                  id="cvvError"
                                  className="error-text hpf-form-error"
                              ></span>
                            </div>
                          </div>
                        </div>
                        {
                            !processingFeeCalculated && <button
                                type="button"
                                className="btn btn-danger btn-block shadow-sm"
                                id="paymentButton"
                                onClick={handleSurcharge}
                            >
                              Proceed
                              <span
                                  className="spinner-border spinner-border-sm"
                                  style={{display: "none"}}
                                  role="status"
                                  aria-hidden="true"
                                  id="spinner"
                              ></span>
                            </button>
                        }
                      </div>
                    </Card.Body>
                  </div>
                </div>
              </div>
          )}

          {initTokenRequestStatus === HPF_INIT_TOKEN_REQUEST_FAILED && (
              <div className="status-alert status-error">
                Unable to load credit card payment form.
              </div>
          )}
          {paymentRequestStatus === PAYMENT_REQUEST_FAILED && (
              <PaymentFailedResponse retry={retry}/>
          )}
          {(surchargeSuccess && (paymentRequestStatus === PAYMENT_REQUEST_SUCCESS)) && (
              <PaymentSuccessresponse/>
          )}
          {paymentProcessing && <LoadingSpinner/>}

          {(processingFeeCalculated && paymentRequestStatus !== PAYMENT_REQUEST_FAILED && submitButtonEnabled) &&
              <button
                  type="button"
                  className="btn btn-danger btn-block shadow-sm"
                  id="paymentSubmitButton"
                  onClick={performPayment}
              >Submit Payment</button>
          }

        </div>
      </div>
  );
};

const PaymentFailedResponse = ({retry}) => (
    <>
      <div className="d-flex justify-content-center">
        <img src="assets/failed.svg" alt="Novatti" width="80"/>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <h2 style={{color: "#EF6060"}}>Payment Failed!</h2>
      </div>
      <div className="d-flex justify-content-center">
        <button
            type="button"
            className="btn btn-danger btn-block shadow-sm"
            id="paymentButton"
            onClick={retry}
        >
          Please Retry
        </button>
      </div>
    </>
);

const PaymentSuccessresponse = () => (
    <>
      <div className="d-flex justify-content-center">
        <img src="assets/success.svg" alt="Novatti" width="80"/>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <h2 style={{color: "#09c04c"}}>Payment Successful!</h2>
      </div>
    </>

);

export default HPFForm;
