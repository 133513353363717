import React from "react";
import {PM_STATIC_DATA} from "../utils/constants";

function PaymentMethod(props, state) {
  const { selectedPaymentMethod, id, amount, disabled } = props;
  return (
      <div
          className={`payment-method-wrapper ${
              selectedPaymentMethod && selectedPaymentMethod.payment_method === id
                  ? "selected"
                  : ""
          }`}
          disabled={disabled}
          onClick={() => props.onClick()}
      >
        <div className={`${disabled ? "grey-payment-method-icon" : "payment-method-icon"}`}>
          <img
              src={PM_STATIC_DATA[id].logo}
              alt={PM_STATIC_DATA[id].title}
          />
        </div>

        <div className="payment-method-name">{PM_STATIC_DATA[id].title}</div>

        {props.showFees ?
            <div className="payment-method-amount">$ {amount.toFixed(2)}</div>
            : null
        }

      </div>
  );
}

export default PaymentMethod;
