import React from "react";

function PaymentInvoice(props, state) {
  const { paymentMethod, paymentInfo, processingFee, processedFeeFetch, getCardNumberCallCall } = props;
  const isReady = paymentInfo;

  if (!isReady) {
    return "";
  }

  const total = () => {
    console.info(`Balance with ${paymentInfo.balance? paymentInfo.balance : 0.0} and processed fee is ${processedFeeFetch()}`)
    if (!paymentMethod) {
      return '--';
    } else if (!paymentInfo.balance) {
      return `$ 0`
    } else if (paymentInfo.balance && !processedFeeFetch()) {
      return `$ ${paymentInfo.balance.toFixed(2)}`
    } else if (paymentInfo.balance && processedFeeFetch()) {
      return `$ ${(parseFloat(paymentInfo.balance) + parseFloat(processedFeeFetch())).toFixed(2)}`;
    }
  }

  return (
      <div className="payment-invoice">
        <ul className="invoice-item-list">

          <li className="invoice-item">
            <div className="invoice-item-row">
              <div className="invoice-item-label">Amount to be paid</div>
              <div className="invoice-item-amount">
                {paymentInfo && paymentInfo.balance != null ? `$ ${paymentInfo.balance.toFixed(2)}` : "--"}
              </div>
            </div>
          </li>

          <li className="invoice-item">
            <div className="invoice-item-row">
              <div className="invoice-item-label">Processing Fee</div>
              <div className="invoice-item-amount">
                {(paymentMethod && processedFeeFetch()) ? `$ ${processedFeeFetch()}` : "--"}
              </div>
            </div>
          </li>

          <hr />

          {getCardNumberCallCall() &&
              <li className="invoice-item">
                <div className="invoice-item-row">
                  <div className="invoice-item-label"><b>Card Number</b></div>
                  <div className="invoice-item-amount">
                    <b>{getCardNumberCallCall()}</b>
                  </div>
                </div>
              </li>
          }

          <li className="invoice-item">
            <div className="invoice-item-row total">
              <div className="invoice-item-label">Total</div>
              <div className="invoice-item-amount">
                {total()}
              </div>
            </div>
          </li>

        </ul>
      </div>
  );
}

export default PaymentInvoice;
