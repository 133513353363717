import React from "react";
import {PM_STATIC_DATA} from "../utils/constants";
import LabelValuePair from "./LabelValuePair";
import {refresh} from "../utils/CommonUtils";

function PaymentSummary(props) {
  const {
    paymentIdValue,
    paymentIdLabel,
    paymentAmount,
    paymentUserFullName,
    paymentUserEmail,
    paymentMethod,
    displayLineItems,
    lineItems,
    onGetProcessedFee,
    getCardNumberCallCall
  } = props;

  console.log("selected method", paymentMethod);

  function getLineItems() {
    return <div className="d-flex flex-column">
      <div className={`label-value-pair mb-2 right`}>
        <div className="label">Charged Items</div>
      </div>
      {
        lineItems.map(item => <div className="d-flex flex-column">
              <div className="d-flex justify-content-between mb-2 gap-5">
                <div className="label-value-pair">
                  <div className="value">{item.description}</div>
                </div>
                <div className="label-value-pair">
                  <div className="value">{`$ ${item.amount.toFixed(2)}`}</div>
                </div>
              </div>
            </div>
        )
      }
    </div>
  }

  return (
      <div className="payment-summary-wrapper">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-between">
            <div className="mb-3">
              <LabelValuePair label={paymentIdLabel} value={paymentIdValue}/>
            </div>
            <div className="mb-3">
              <LabelValuePair label="Name" value={paymentUserFullName}/>
            </div>
            <div className="mb-3">
              <LabelValuePair label="Email" value={paymentUserEmail}/>
            </div>
            {
              displayLineItems ? <div className="mb-3">
                <LabelValuePair
                    label="Total Due"
                    value={`$ ${paymentMethod ? paymentAmount.toFixed(2): 0.00}`}
                    alignRight={false}
                />
              </div> : ""
            }
            {paymentMethod && (
                <div className="payment-summary-row">
                  <div className="payment-summary-cell">
                    <div className="mb-2">
                      <LabelValuePair
                          label="Payment Method"
                          value={PM_STATIC_DATA[paymentMethod.payment_method].title}
                      />
                    </div>
                    <div className="mb-2">
                      {onGetProcessedFee() &&
                          <LabelValuePair
                              label="Processing Fee"
                              value={`$ ${parseFloat(onGetProcessedFee()).toFixed(2)}`}
                              alignRight={false}
                          />
                      }
                      {onGetProcessedFee() &&
                          <LabelValuePair
                              label="Total"
                              value={`$ ${(parseFloat(paymentAmount) + parseFloat(onGetProcessedFee())).toFixed(2)}`}
                              alignRight={false}
                          />
                      }
                    </div>
                    <div className="link" onClick={refresh}>
                      Switch payment method
                    </div>
                  </div>
                </div>
            )}
          </div>
          <div className="d-flex">
            {!displayLineItems ? <div className="payment-summary-cell">
              <LabelValuePair
                  label="Total Due"
                  value={`$ ${paymentAmount.toFixed(2)}`}
                  alignRight={true}
              />
            </div> : getLineItems()}
          </div>
        </div>

      </div>
  );
}

export default PaymentSummary;
