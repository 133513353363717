import React from "react";
import "./App.scss";
import ErrorPage from "./components/ErrorPage";
import LoadingSpinner from "./components/LoadingSpinner";
import PageContent from "./components/PageContent";
import PaymentSummary from "./components/PaymentSummary";
import SelectedPaymentDrawer from "./components/SelectedPaymentDrawer";
import { isMobile } from "./utils/CommonUtils";
import {
  initializePayment,
  loadPaymentInfo,
  loadPaymentMethods
} from "./utils/RequestHandlers";
import {CREDITCARD, LINK_ACCESS_TOKEN, PAYMENT_METHODS} from "./utils/constants";
import PaymentInvoice from "./components/PaymentInvoice";
class PaymentApp extends React.Component {
  handleWindowResize = () => {
    this.setState({ isMobile: isMobile() });
  };

  constructor(props) {
    super(props);
    this.state = {
      // flags
      isMobile: isMobile(),
      isLoading: true,
      currentView: "payment-method-selection",
      isDrawerShown: false,
      isPaymentInfoLoaded: false,
      isPaymentMethodsLoaded: false,
      error: false,
      // data
      paymentInfo: null,
      paymentMethods: [],
      paymentMethod: null,
      paymentTransactionId: null,
      paymentFrameUrl: null,
      paymentQRCode: null,
      paymentWXCodeURL: null,
      processedFee: null,
      cardNumber: null
    };
  }

  loadHostedPaymentElementsScript() {
    // need hpfOptions declared in global scope,
    // prior to loading hpf script
    window.hpfOptions = null;
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_HOSTED_PAYMENTS_URLL;
    script.async = true;
    head.appendChild(script);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize.bind(this));
    this.loadHostedPaymentElementsScript();
    try {
      const params = window.location.search.substr(1);
      const paymentInfo = await loadPaymentInfo(params);
      sessionStorage.setItem(LINK_ACCESS_TOKEN, paymentInfo.paymentInfo.access_token);
      document.title = paymentInfo.paymentInfo.merchant.page_configuration.title;
      const favicon = getFaviconEl();
      favicon.href = paymentInfo.paymentInfo.merchant.page_configuration.favicon_href;
      const paymentMethods = await loadPaymentMethods(
        paymentInfo.paymentInfo.access_token,
        paymentInfo.paymentInfo.balance,
        paymentInfo.paymentInfo.account_type
      );
      this.setState({
        ...paymentInfo,
        ...paymentMethods,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        error: true,
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  getCardNumber() {
    return this.state.cardNumber;
  }

  onCardNumberReceived(cardNumber) {
    console.log(`Card number: ${cardNumber}`)
    this.setState({
      cardNumber: cardNumber
    });
  }

  getProcessedFee() {
    return !this.state.processedFee ? this.state.processedFee : parseFloat(this.state.processedFee).toFixed(2);
  }

  onProcessedFeeCalculated(processedFee) {
    console.log(`Processed fee calculated ${processedFee}`)
    this.setState({
      processedFee: processedFee
    });
  }

  onPaymentMethodSelect(paymentMethod) {
    this.setState({
      paymentMethod: paymentMethod,
      isDrawerShown: true,
    });
  }

  onPaymentMethodProceed() {
    this.setState({
      isLoading: true,
    });
    initializePayment(
        this.state.paymentInfo.access_token,
        this.state.paymentMethod
    )
        .then((state) => {
          this.setState({
            ...state,
            isLoading: false,
            isDrawerShown: false,
            currentView: "payment-form",
          });
        });
  }

  closeDrawer() {
    this.setState({ isDrawerShown: false, paymentMethod: null });
  }

  getPageContent() {}

  render() {
    const {
      currentView,
      isDrawerShown,
      paymentMethod,
      paymentMethods,
      paymentInfo,
      isLoading,
      isMobile,
      paymentTransactionId,
      paymentFrameUrl,
      paymentQRCode,
      paymentWXCodeURL,
      error,
      processingFee
    } = this.state;

    return (
        <>
          {isLoading && <LoadingSpinner isFullScreen="true" />}

          {error && <ErrorPage />}

          {!error && paymentInfo && paymentMethods && (
              <div className={`payment-page-wrapper ${isLoading ? "loading" : ""}`}>
                {paymentInfo && (
                    <div className="payment-summary">
                      <div className="heading">
                        <span>{paymentInfo.merchant.name}</span>
                        <img src={paymentInfo.merchant.brand_image} height="50px"/>
                      </div>

                      <PaymentSummary
                          paymentIdValue={paymentInfo.reference_id}
                          paymentIdLabel={paymentInfo.reference_display_name}
                          paymentAmount={paymentInfo.balance}
                          paymentUserFullName={this.getPaymentUserFullName(paymentInfo)}
                          paymentUserEmail={paymentInfo.customer.email}
                          paymentMethod={
                            currentView === "payment-form" ? paymentMethod : null
                          }
                          displayLineItems={paymentInfo.display_line_items}
                          lineItems={paymentInfo.line_items || []}
                          onGetProcessedFee={this.getProcessedFee.bind(this)}
                          getCardNumberCall={this.getCardNumber.bind(this)}
                      />
                    </div>
                )}

                {paymentMethods && (
                    <div className="payment-page-content">
                      <PageContent
                          currentView={currentView}
                          paymentMethods={paymentMethods}
                          isLoading={isLoading}
                          paymentInfo={paymentInfo}
                          paymentMethod={paymentMethod}
                          paymentTransactionId={paymentTransactionId}
                          paymentFrameUrl={paymentFrameUrl}
                          paymentQRCode={paymentQRCode}
                          paymentWXCodeURL={paymentWXCodeURL}
                          onPaymentMethodSelect={this.onPaymentMethodSelect.bind(this)}
                          onProceed={this.onPaymentMethodProceed.bind(this)}
                          onProcessedFeeCalculated={this.onProcessedFeeCalculated.bind(this)}
                          processingFee={this.state.processingFee}
                          onGetProcessedFee={this.getProcessedFee.bind(this)}
                          onCardNumberReceivedCall={this.onCardNumberReceived.bind(this)}
                          getCardNumberCall={this.getCardNumber.bind(this)}
                      />
                    </div>
                )}

                <div className="page-footer">
                  <img src="assets/novatti_logo.svg" alt="Novatti" width="80" />
                  <div>Novatti Pty Ltd © 2022</div>
                </div>
              </div>
          )}

          {/*
          todo move this to a component poratal
          ---
          portal can be opened using ReactDom.createPortal(<Component />, domNode);
          but closing mechanism is not working properly (when clicked on backdrop)
          same behavior will be needed for the modal as well
          */}
          {isMobile && isDrawerShown && paymentMethod && paymentInfo && (
              <SelectedPaymentDrawer
                  paymentMethod={paymentMethod}
                  paymentInfo={paymentInfo}
                  onProceed={this.onPaymentMethodProceed.bind(this)}
                  onExit={this.closeDrawer.bind(this)}
                  processingFee={this.state.processingFee}
                  onGetProcessedFee={this.getProcessedFee.bind(this)}
                  getCardNumberCall={this.getCardNumber.bind(this)}
              />
          )}
        </>
    );
  }

  getPaymentUserFullName(paymentInfo) {
    let s = [paymentInfo.customer.first_name, paymentInfo.customer.last_name].filter(Boolean).join(' ');
    console.log("Customer Full Name "+s);
    return s;
  }
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default PaymentApp;
