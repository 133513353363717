import {
    CREDITCARD, DOMESTIC, INTERNATIONAL,
    PM_ALI_PAY,
    PM_CREDIT_CARD_DOMESTIC,
    PM_CREDIT_CARD_INTERNATIONAL,
    PM_DIRECT_DEBIT,
    PM_STATIC_DATA,
    PM_UNION_PAY,
    PM_WE_CHAT_PAY
} from '../utils/constants';
import DirectDebitForm from './DirectDebitForm';
import HPFForm from './HPFForm';
import {fetchIframeUrl} from "../utils/RequestHandlers";

function PaymentForm(props) {
    const {
        paymentInfo,
        paymentMethod,
        paymentTransactionId,
        paymentFrameUrl,
        paymentQRCode,
        paymentWXCodeURL,
        onCalculatedProp,
        onCardNumberReceivedC,
        iframeUrl
    } = props;

    switch (paymentMethod.payment_method) {
        case PM_DIRECT_DEBIT:
            return <DirectDebitForm paymentInfo={paymentInfo} />;
        case PM_WE_CHAT_PAY:
            return <div className={PM_STATIC_DATA[paymentMethod.payment_method].frameStyleClass}>
                <div className="wx-link">
                    <a id="we-chat-pay-link-2" href={paymentWXCodeURL}>
                        <img src="assets/payment-methods/wechat.svg" width="180" alt="WeChat Pay" />
                        <span>Click here to pay via WeChat App</span>
                    </a>
                </div>

                <div className="or">or</div>

                <div className="qr-code">
                    <img id="we-chat-pay-qr-code" src={`data:image/png;base64,${paymentQRCode}`} width="250" alt="qr code" />

                    <span>Scan QR code with WeChat App</span>
                </div>
            </div>
        case PM_ALI_PAY:
        case PM_UNION_PAY:
            return (<div className={PM_STATIC_DATA[paymentMethod.payment_method].frameStyleClass}>
                <iframe className="payment-method-frame" src={paymentFrameUrl} title={paymentMethod.payment_method} />
                <div id="customId1" class="form-control" data-hpf="cardNumber"></div>
            </div>);
        case PM_CREDIT_CARD_DOMESTIC:
            return <iframe src={iframeUrl} title={"PaymentIframe"}
                           frameBorder="0" border="0"
                           width="100% " height="360px"></iframe>
        case PM_CREDIT_CARD_INTERNATIONAL:
            return <iframe src={iframeUrl} title={"PaymentIframe"}
                           frameBorder="0" border="0"
                           width="100% " height="360px"></iframe>
        case CREDITCARD:
            return <HPFForm
                paymentInfo={paymentInfo}
                paymentLinkToken={paymentInfo.access_token}
                merchantId={paymentInfo.merchant.merchant_id}
                type={CREDITCARD}
                onCalculated={onCalculatedProp.bind(this)}
                onCardNumberReceivedCallCall={onCardNumberReceivedC.bind(this)}
                method={CREDITCARD}
            />
        default:
            return <div>Unknown payment method</div>
    }
}

export default PaymentForm;