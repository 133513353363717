import {
  ACQUIRING,
  CREDITCARD, HPF_INIT_TOKEN,
  PM_ALI_PAY,
  PM_CREDIT_CARD_DOMESTIC,
  PM_CREDIT_CARD_INTERNATIONAL,
  PM_DIRECT_DEBIT,
  PM_UNION_PAY,
  PM_WE_CHAT_PAY,
} from "./constants";

const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY;
const PAYMENT_INFO_API = process.env.REACT_APP_PAYMENT_INFO_API;
const PAYMENT_METHODS_API = process.env.REACT_APP_PAYMENT_METHODS_API
const PAYMENT_INIT_API = process.env.REACT_APP_PAYMENT_INIT_API;
const SURCHARGE_API = process.env.REACT_APP_SURCHARGE_API   // /surcharge
const HPF_TOKEN_INIT_API = process.env.REACT_APP_HPF_TOKEN_INIT_API;
const HPF_CLIENT_NAME = process.env.REACT_APP_HPF_CLIENT_NAME;

export function surcharge(secureToken, accessToken, userId, alphaMid, amount) {
  const payload = {
    'secure_token': secureToken,
    'merchant_id': alphaMid,
    'user_id': userId,
    'currency': 'AUD',
    'amount': String(amount),
    'method': "CC",
    "channel_type": "07",
    "request_type": "surcharging",
    "alpha_token": sessionStorage.getItem(HPF_INIT_TOKEN)
  };
  const config = {
    method: "POST",
    mode: "cors",
    headers : {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(payload)
  }
  return fetch(SURCHARGE_API, config)
      .then((res) => {
        return res.json();
      }).then((data) => {
        return data
      });
}

export function loadPaymentInfo(params) {
  const payload = {
    queryParam: params,
    paymentPage: CLIENT_KEY,
  };
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetch(PAYMENT_INFO_API, config)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      console.log(data);
      return {
        isPaymentInfoLoaded: true,
        paymentInfo: data,
      };
    });
}

export function loadPaymentMethods(token, amount, accountType) {
  const payload = {
    request_type: "fee_details",
    gross_amount: amount,
  };
  const config = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  return fetch(PAYMENT_METHODS_API, config)
    .then((res) => res.json())
    .then((data) => ({
      isPaymentMethodsLoaded: true,
      paymentMethods: processPaymentMethodsForAcquiring(data, accountType),
    }));
}

export function processPaymentMethodsForAcquiring(paymentMethods, accountType) {
  if (accountType === ACQUIRING) {
    const modifiedPaymentMethods = [];
    paymentMethods.forEach((method => {
      if (!method.payment_method.startsWith(CREDITCARD)) {
        modifiedPaymentMethods.push(method);
      }
    }));
    modifiedPaymentMethods.unshift({"payment_method": CREDITCARD});
    return modifiedPaymentMethods;
  } else {
    // TODO: Modify this accordingly
    return paymentMethods;
  }
}

export function fetchHPFInitToken(merchantId, token) {
  const payload = {
    "client-name": HPF_CLIENT_NAME,
    "merchant-id": merchantId,
  };
  const config = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  return fetch(HPF_TOKEN_INIT_API, config)
    .then((res) => res.json())
    .then((data) => {
      return { hpfInitToken: data.access_token, alphaMid: data.alpha_mid };
    });
}

export async function fetchIframeUrl(paymentMethod, token) {
  let payload = {
    request_type: "payment",
    payment_method: paymentMethod.payment_method,
  };
  const res = await fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  const response = await res.json();
  console.log(`Initpayment response: ${JSON.stringify(response)}`);
  return response.iframe_url;
}

export async function initializePayment(token, paymentMethod) {
  let payload;
  switch (paymentMethod.payment_method) {
    case CREDITCARD:
    case PM_DIRECT_DEBIT:
    case PM_CREDIT_CARD_DOMESTIC:
    case PM_CREDIT_CARD_INTERNATIONAL:
      return {};
    case PM_ALI_PAY:
    case PM_WE_CHAT_PAY:
    case PM_UNION_PAY:
      payload = {
        request_type: "payment",
        payment_method: paymentMethod.payment_method,
      };
      break;
    default:
      payload = null;
  }

  if (!payload) {
    this.setState({
      error: "Failed to process request",
    });
  }

  const res = await fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  const result_1 = await res.json();
  switch (paymentMethod.payment_method) {
    case PM_DIRECT_DEBIT:
      // expect
      // {
      //     "message": "Direct debit request successfully submitted. Please allow 2 business days for funds to be debited.",
      //     "transaction_id": "e9eddfb4-0abd-48df-a935-cac4386d7665"
      // }
      return {
        paymentFrameUrl: null,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: result_1.transaction_id,
      };
    case PM_WE_CHAT_PAY:
      // expect
      // {
      //     "qr_code": "--------",
      //     "transaction_id": "388b8d81-9ac3-4616-816d-535f6766bea2",
      //     "wx_code_url": "weixin://wxpay/bizpayurl?pr\u003dybjxrHTzz"
      // }
      return {
        paymentFrameUrl: null,
        paymentQRCode: result_1.qr_code,
        paymentWXCodeURL: result_1.wx_code_url,
        paymentTransactionId: result_1.transaction_id,
      };
    case PM_ALI_PAY:
    case PM_UNION_PAY:
      // expect
      // {
      //     "page_url": "~~~",
      //     "transaction_id": "388b8d81-9ac3-4616-816d-535f6766bea2",
      // }
      return {
        paymentFrameUrl: result_1.page_url,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: result_1.transaction_id,
      };
    case PM_CREDIT_CARD_DOMESTIC:
    case PM_CREDIT_CARD_INTERNATIONAL:
      // expect
      // {
      //     "iframe_url": "~~~",
      //     "transaction_id": "388b8d81-9ac3-4616-816d-535f6766bea2",
      // }
      return {
        paymentFrameUrl: result_1.iframe_url,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: result_1.transaction_id,
      };
    default:
      return {
        paymentFrameUrl: null,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: null,
      };
  }
}

export function handleDirectDebitRequest(payload, token) {
  return fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error();
    }
  });
}

export function handleCreditPaymentRequest(request) {
  const { secureToken, bearerToken, paymentLinkToken, type,
    amount, userId, residentId, customerName, surchargeAmount, method, issuerCountryCode } = request;
  const payload = {
    processor:"acquiring",
    type: type,
    secure_token: secureToken,
    bearer_token: bearerToken,
    payment_method: method,
    request_type: "payment",
    currency: "AUD",
    amount: String(amount),
    user_id: userId,
    resident_id: residentId,
    customer_name: customerName,
    surcharge_amount: surchargeAmount,
    issuer_country_code: issuerCountryCode
  };
  return fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${paymentLinkToken}`,
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    if (res.ok) {
      return {response: res.json(), status: String(res.status)};
    } else {
      throw new Error();
    }
  });
}
