import React from "react";

function FormField(props) {
  const {
    id,
    type,
    name,
    label,
    errorMessage,
    isRequired,
    isNumber,
    maxLength,
    ariaDesc,
    className
  } = props;

  const onKeyPress = (e) => {
    if (isNumber) {
      if (e.which < 48 || e.which > 57) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className={`form-group form-field-wrapper ${className || ''} ${errorMessage ? 'error' : ''}`}>
      <label htmlFor={id} className={isRequired ? "required-field" : ""}>
        {label}
      </label>
      <input
        type={type}
        className="form-control"
        id={id}
        name={name}
        autoComplete="off"
        aria-describedby={ariaDesc}
        onKeyPress={onKeyPress}
        maxLength={maxLength}
      />
      <small className="error-message">{errorMessage}</small>
    </div>
  );
}

export default FormField;
